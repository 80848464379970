import React from "react";
import Layout from "../../layouts/index";
import imgcasestudy1 from '../../images/casestudy1.png';

class casestudy1 extends React.Component {
  render() {
    return (
      <Layout bodyClass="casestudy">
        <div className="intro pt-6 pt-sm-8 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h1>Enhancing the Supply-Chain in Premium Beef Production</h1>
              </div>
            </div>
            <hr className="section-divider mt-sm-7 mb-4 mb-sm-9" />
            <div className="row">
              <div className="col-12">
                <h2 className="title-3 text-primary mb-1">Preamble:</h2>
                <p>
                  An Australian cattle intermediary (our customer), produces a
                  unique type of meat and sells the meat to high end restaurants
                  in Australia and overseas. Given the high-end nature of the
                  meat products, it is critical for our customer to guarantee
                  the integrity of the meat it supplies to the restaurants. To
                  maintain its competitive advantage, our customer wanted to
                  provide its clients a means of accessing the source of the
                  animal from the paddock to the restaurant
                </p>
                {/* <h2 className="title-3 text-primary mb-1">
                  Customers Supply Chain Process:
                </h2> */}
                {/* <h2 className="title-3 text-primary mb-2">Beef Producers:</h2>
                <p>Farmers that source calves to produce meat.</p>
                <h2 className="title-3 text-primary mb-2">Feedlots:</h2>
                <p>Fattens calves on a commercial basis for meat production.</p>
                <h2 className="title-3 text-primary mb-2">Abattoirs:</h2>
                <p>
                  That process live animals into commercial meat products(cuts)
                </p>
                <h2 className="title-3 text-primary mb-2">
                  Logistics Providers:
                </h2>
                <p>
                  Companies that transport live animals from one destination to
                  another on acommercial basis
                </p>
                <h2 className="title-3 text-primary mb-2">Restaurants:</h2>
                <p className="m-0">Buys processed meat cuts to serve its customers. </p> */}

                <div className="mb-2">
                  <div className="casst-img"> <img className="img-fluid" className="casst" src={imgcasestudy1} /></div>
                </div>
                <h2 className="title-3 text-primary mb-2">
                  Regulatory Framework:
                </h2>
                <p>
                  Australia maintains a National Livestock Identification System
                  (NLIS), for the identification and traceability of cattle.
                  NLIS has three elements to enable lifetime traceability of an
                  animal, which comprises of an electronic ear tag, Property
                  Identification Code (PIC) and a national online data base with
                  API access.
                </p>
                <ul className="custom-list accent pr-lg-6">
                  <li>
                    <p>
                      All cattle are registered with NLIS, each registered
                      animal is uniquely identified with a NLIS number. All
                      cattle are physically tagged with a NLIS accredited device
                      which contains a RFID.
                    </p>
                  </li>
                  <li>
                    <p>
                      A Property Identification Code (PIC) is an eight-character
                      code allocated by the Department of Primary Industries
                      (DPI) to identify a livestock-producing property.
                      Producers must have a PIC to move livestock on and off a
                      property – it forms the basis of Australia’s food safety
                      and traceability programs.
                    </p>
                  </li>
                  <li>
                    <p>
                      Online database where all NLIS and PIC are stored. API
                      access is provided to interested parties to access the DB
                      content.
                    </p>
                  </li>
                </ul>
                <h2 className="title-3 text-primary mb-2">
                  Customer Process Flow
                </h2>
                <p>
                  Customer initiates the process by stating its requirements,
                  consisting of weight of each calf and other characteristic of
                  each calf.{" "}
                </p>
                <p>
                  Interested beef producers submit expressions of interest to
                  the customer
                </p>
                <p>
                  Customer selects suitable beef producer, negotiates price with
                  customer.
                </p>
                <p>
                  Beef producer fulfills customer requirement, then invoices
                  customer Selected logistics provider transports herd from beef
                  producer to feedlot, then invoices customer Feedlot feeds the
                  herd, invoices customer on a weekly basis. Invoice is based on
                  amount of feed, the herd consumed for that week. When animals
                  reach a certain weight, customer invites abattoirs to bid for
                  processing the herd into commercial meat products. Logistics
                  provider transports herd to abattoir from feedlot, then
                  invoices customer Abattoir process animals into meat products,
                  then invoices customer Meat products are distributed to
                  restaurants both domestic and overseas. Logistics providers
                  fulfil this distribution and invoice customer
                </p>
                <p>
                  From a regulatory compliance point of view, each NLIS must be
                  transferred from originating PIC to destination PIC, including
                  when the herds are being transported. This task is the
                  responsibility of the customer and was done manually by its
                  employees. Given its complexity and volatility management of
                  this process consumes significant amount of manual effort and
                  was not scalable. It required an automated solution.
                </p>
                <h2 className="title-3 text-primary mb-2">The Solution</h2>
                <p>
                  Web application was developed for the customer, where all
                  stakeholders to the process can register and access NLIS
                  Database. Access was facilitated by the API’s that the
                  regulator had orchestrated. This way each stakeholder manages
                  each step of the process, for example beef producer would move
                  NLIS’s from its PIC to the feedlots PIC. The web application
                  ensured that upkeep of the NLIS was decentralised from the
                  customer to each stakeholder, thereby simplifying the process.
                  Further the application enabled the customer to make visible
                  its herd requirements digitally, so that interested beef
                  producers can submit their expression of interest digitally,
                  fulfil the requirements and raise RCTI invoice for payment by
                  the customer. Application enabled the collaboration between
                  customer, beef producer and logistics provider, culminating
                  the logistics provider submitting invoices for payment to the
                  customer via the application. Weekly invoices submitted by the
                  feedlot were also enabled via the application. The entire
                  application data reference point was the NLIS and the PIC.
                </p>
                <p>
                  Digitising this process meant, the customer complied with the
                  regulatory requirements, ensuring that each animal was
                  traceable during its life cycle. Further all customer
                  requirements, fulfilment and invoicing for this process was
                  handled by the application, ensuring all stakeholders
                  collaborated digitally with each other via the application.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default casestudy1;
